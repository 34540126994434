import React, { useCallback, useState } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import './App.css';
import particlesOptions from "./particles.json";
import Tilt from 'react-parallax-tilt';
import Countdown from "react-countdown";


function App() {
  const particlesInit = useCallback(main => {
    loadFull(main);
  }, []);
  const [mintAmount, setMintAmount] = useState(1);

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };
  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    // if (newMintAmount > 3) {
    //   newMintAmount = 3;
    // }
    setMintAmount(newMintAmount);
  };

  const comingSoon = false;
  const preSale = false;
  const publicSale = true;
  
  const currency = 'ETH';
  const preSalePrice = 0.5;
  const publicSalePrice = 1;

  const connectWallet = true;

  if (comingSoon === true) {
    return (
      <div className="app">
        <Particles options={particlesOptions} init={particlesInit} />
        <header className="app-header">
          <a href='https://divitium.com' target="top">
            <img src="./white-logo.png" className="app-logo" alt="logo" />
          </a>
          <h1>Presale will start in
            <br />
            <div className='app-presale'>
              {/* epoch */}
              <Countdown date={new Date(1663812750000)} onComplete={() => window.location.reload(false)} />
            </div>
          </h1>
          <div className='app-spacing'>
            <a href='https://twitter.com/Divitium_DC01' target="top">
              <img src="./twitter.png" className="app-social" alt="twitter" />
            </a>
            <a href='https://www.instagram.com/divitium/' target="top">
              <img src="./instagram.png" className="app-social" alt="instagram" />
            </a>
          </div>
        </header>
      </div>
    )
  }
  else if (preSale === true) {
    return (
      <div className="app">
        <Particles options={particlesOptions} init={particlesInit} />
        <header className="app-header">
          <a href='https://divitium.com' target="top">
            <img src="./white-logo.png" className="app-logo" alt="logo" />
          </a>
          <Tilt className='parallax-effect' perspective={500} scale={1.1} transitionSpeed="200" tiltReverse={false} tiltMaxAngleX={7} tiltMaxAngleY={7} glareEnable={true} glareMaxOpacity={1} glareBorderRadius="30px" glarePosition="all">
            <div className='app-mint'>
              <h1>Presale</h1>
              <p>Mint your nft</p>
              <div className='app-left-button'>
                <button type="button" className="app-quantity-button" onClick={(e) => { e.preventDefault(); decrementMintAmount(); }}><svg className='app-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg></button>
              </div>
              <div className='app-mint-amount'>
                <span>{mintAmount}</span>
              </div>
              <div className='app-right-button'>
                <button type="button" className="app-quantity-button" onClick={(e) => { e.preventDefault(); incrementMintAmount(); }}><svg className='app-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg></button>
              </div>
              <p>{mintAmount * preSalePrice} {currency} + gas fee</p>
              {connectWallet === false ? (
                <button type="button" className="app-mint-button" onClick={(e) => { e.preventDefault(); }}>CONNECT WALLET</button>
              ) : (
                <button type="button" className="app-mint-button" onClick={(e) => { e.preventDefault(); }}>MINT</button>
              )}
              {/* Ejemplo
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <button disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  > CONNECT WALLET </button>
                ) : (
                  <button disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}>{claimingNft ? "LOADING" : "MINT"}</button>
                )} */}
              <p>Hurry up, presale ends in<br />
                <Countdown date={new Date(1663812750000)} onComplete={() => window.location.reload(false)} />
              </p>
            </div>
          </Tilt>
          <div className='app-spacing'>
            <a href='https://twitter.com/Divitium_DC01' target="top">
              <img src="./twitter.png" className="app-social" alt="twitter" />
            </a>
            <a href='https://www.instagram.com/divitium/' target="top">
              <img src="./instagram.png" className="app-social" alt="instagram" />
            </a>
          </div>
        </header>
      </div>
    )
  }
  else if (publicSale === true) {
    return (
      <div className="app">
        <Particles options={particlesOptions} init={particlesInit} />
        <header className="app-header">
          <a href='https://divitium.com' target="top">
            <img src="./white-logo.png" className="app-logo" alt="logo" />
          </a>
          <Tilt className='parallax-effect' perspective={500} scale={1.1} transitionSpeed="200" tiltReverse={false} tiltMaxAngleX={7} tiltMaxAngleY={7} glareEnable={true} glareMaxOpacity={1} glareBorderRadius="30px" glarePosition="all">
            <div className='app-mint'>
              <h1>MINT</h1>
              <p>get your nft</p>
              <div className='app-left-button'>
                <button type="button" className="app-quantity-button" onClick={(e) => { e.preventDefault(); decrementMintAmount(); }}><svg className='app-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg></button>
              </div>
              <div className='app-mint-amount'>
                <span>{mintAmount}</span>
              </div>
              <div className='app-right-button'>
                <button type="button" className="app-quantity-button" onClick={(e) => { e.preventDefault(); incrementMintAmount(); }}><svg className='app-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg></button>
              </div>
              <p>{mintAmount * publicSalePrice} {currency} + gas fee</p>
              {connectWallet === false ? (
                <button type="button" className="app-mint-button" onClick={(e) => { e.preventDefault(); }}>CONNECT WALLET</button>
              ) : (
                <button type="button" className="app-mint-button" onClick={(e) => { e.preventDefault(); }}>MINT</button>
              )}
              {/* Ejemplo
                {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                  <button disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  > CONNECT WALLET </button>
                ) : (
                  <button disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}>{claimingNft ? "LOADING" : "MINT"}</button>
                )} */}
            </div>
          </Tilt>
          <div className='app-spacing'>
            <a href='https://twitter.com/Divitium_DC01' target="top">
              <img src="./twitter.png" className="app-social" alt="twitter" />
            </a>
            <a href='https://www.instagram.com/divitium/' target="top">
              <img src="./instagram.png" className="app-social" alt="instagram" />
            </a>
          </div>
        </header>
      </div>
    )
  }
  else {
    return (
      <div className="app">
        <Particles options={particlesOptions} init={particlesInit} />
        <header className="app-header">
          <a href='https://divitium.com' target="top">
            <img src="./white-logo.png" className="app-logo" alt="logo" />
          </a>
          <h1>Sold Out!</h1>
          <br/>
          <p>Did you miss public sale?
          <br/>
          Get into <a href="#">OpenSea.io</a>
          <br/>
          To buy yours now</p>
          <div className='app-spacing'>
            <a href='https://twitter.com/Divitium_DC01' target="top">
              <img src="./twitter.png" className="app-social" alt="twitter" />
            </a>
            <a href='https://www.instagram.com/divitium/' target="top">
              <img src="./instagram.png" className="app-social" alt="instagram" />
            </a>
          </div>
        </header>
      </div>
    )
  }
}

export default App;
